import { v4 as uuidv4 } from 'uuid';

export class CropsEntity {
    constructor(id, name, cropFamilyId, createdAt, tidalFloorPlantDensity,  createdById, updatedById, updatedAt) {

        this.id = id;
        this.name = name;
        this.cropFamilyId = cropFamilyId;
        this.createdAt = createdAt;
        this.tidalFloorPlantDensity = tidalFloorPlantDensity;
        this.createdById = createdById;
        this.updatedAt = updatedAt;
        this.updatedById = updatedById;

        this.guid = uuidv4();
    }
    static create() {
        return new CropsEntity(0, '', '', '', 0.0, '', '', '');
    }

    static createFromObject(crop) {
        return new CropsEntity(crop.id, crop.name, crop.cropFamilyId, crop.createdAt, crop.tidalFloorPlantDensity, crop.updatedAt,
            crop.createdById, crop.updatedById);
    }
}
