<template>
    <b-overlay :show="isLoading">
        <b-card>
            <b-row class="mb-2">
                <b-col class="text-right">
                    <b-button @click="showModalCrop('')" variant="primary" v-if="hasPermission">Add Crop</b-button>
                    <b-modal id="crops-modal" ref="modal" :title="cropEntity.id > 0 ? 'Edit Crop' :'Add crop'" size="lg" centered no-close-on-backdrop no-close-on-esc>
                        <ValidationObserver v-slot="{ passes }">
                            <b-form @submit.prevent="passes(saveCrops)" id="crops-form">
                                <b-row>
                                    <b-col md="6" sm="12">
                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                            <b-form-group label="Crop" :invalid-feedback="errors[0]">
                                                <b-form-input v-model="cropEntity.name" :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                        <b-col md="6" sm="12">
                                            <ValidationProvider rules="required" v-slot="{ errors }">
                                                <b-form-group label="Family" :invalid-feedback="errors[0]" :clearable="false" :state="errors[0] ? false : null">
                                                    <v-select label="text" :options="cropFamily" @search:focus="getCropFamily" @search="getCropFamily"
                                                              :reduce="cropFamilyId => cropFamilyId.value" v-model="cropEntity.cropFamilyId"
                                                              placeholder="Select family">
                                                        <template #no-options>No results</template>
                                                    </v-select>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                </b-row>
                            </b-form>
                        </ValidationObserver>
                        <template #modal-footer>
                            <div class="text-right">
                                <b-button @click="$bvModal.hide('crops-modal')" class="mr-2" variant="outline-secondary"> Cancel</b-button>
                                <b-button type="submit" form="crops-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Save</b-button>
                            </div>
                        </template>
                    </b-modal>
                </b-col>
            </b-row>

            <b-row class="mb-2">
                <b-col sm="5" md="6" class="my-1">
                    <b-input-group size="sm" prepend="Show" append="records">
                        <b-form-select v-model="crops.itemsPerPage" id="per-page-select" size="sm" :options="crops.pageOptions" style="max-width: 5em"></b-form-select>
                    </b-input-group>
                </b-col>
            </b-row>

            <b-table :items="loadData" :fields="filteredFields" :filter="crops.filter" :current-page="crops.currentPage"
                     :per-page="crops.itemsPerPage" empty-text="No records" striped bordered hover small show-empty
                     :responsive="true" :sort-by="crops.sortBy" :sort-desc="crops.sortDesc" ref="cropsTable"
                     class="text-left mb-0" @context-changed="tableContextChanged">

                <template #thead-top>
                    <b-tr class="table-filters">
                        <b-th v-for="(field, index) in filteredFields" :key="field.key" :class="crops.filters[index] && crops.filters[index].value ? 'non-empty' : ''">
                            <b-row v-if="crops.filters[index]">
                                <b-col v-if="crops.filters[index].type === 'STRING'">
                                    <b-input :placeholder="field.label" v-model="crops.filters[index].value" debounce="300"></b-input>
                                </b-col>
                            </b-row>
                        </b-th>
                    </b-tr>
                </template>

                <template #cell(actions)="row" v-if="hasPermission">
                    <b-button-group size="sm" >
                        <b-button size="sm" @click="showModalCrop(row.item)" v-b-tooltip.hover title="Edit" variant="outline-primary">
                            <font-awesome-icon icon="pencil-alt"></font-awesome-icon>
                        </b-button>
                        <b-button size="sm" @click="deleteCrop(row.item.id)" v-b-tooltip.hover title="Delete" variant="outline-primary">
                            <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                        </b-button>
                    </b-button-group>
                </template>

                <template #table-caption>
                    <b-row class="no-gutters">
                        <b-col sm="5" md="6">
                            Show {{ start }} to {{ end }} of {{ crops.totalRows }} record{{crops.totalRows !== 1 ? 's' : ''}}
                        </b-col>
                        <b-col sm="7" md="6" class="my-1">
                            <b-pagination v-model="crops.currentPage" :total-rows="crops.totalRows" :per-page="crops.itemsPerPage" align="right" class="mb-0"></b-pagination>
                        </b-col>
                    </b-row>
                </template>
            </b-table>
        </b-card>
    </b-overlay>
</template>
<script>
import { extend } from 'vee-validate';
import { CropsEntity } from '@/models/crops';
import { cropSaveUrl, cropListUrl, cropDeleteUrl, cropFamilySelectUrl } from '@routes';
import tableStateMixin from '../../mixins/tableState';
import { required } from 'vee-validate/dist/rules';
import $permission from '@/js/permission';

extend('required', {
    ...required,
    message: 'Field required'
});
export default {
    mixins: [tableStateMixin],
    data() {
        return {
            cropEntity: CropsEntity.create(),
            array: [],
            toastTitle: 'Crops',
            isLoading: false,
            crops: {
                fields: [
                    { key: 'name', label: 'Crop', sortable: true },
                    { key: 'cropFamilyName', label: 'Family', sortable: true },
                    // { key: 'createdBy', label: 'Created by', sortable: true, class: 'text-center' },
                    // { key: 'updatedBy', label: 'Updated by', sortable: true, class: 'text-center' },
                    { key: 'actions', label: 'Actions', class: 'text-center' }
                ],
                fieldsList: [
                    { key: 'name', label: 'Crop', sortable: true },
                    { key: 'cropFamilyName', label: 'Family', sortable: true }
                ],
                filters: [
                    { column: 'name', type: 'STRING', value: '', format: '' },
                    { column: 'cropFamilyName', type: 'STRING', value: '', format: '' }
                ],
                filter: '',
                pageOptions: [10, 25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'name',
                sortDesc: false
            },
            breadcrumb: {
                title: 'Crops',
                path: []
            },
            cropFamily: [],
            hasPermission: $permission.check('ROLE_CROP_C')
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.crops, this.tableState);
    },
    methods: {
        loadData(crops) {
            this.isLoading = true;
            if (crops.sortBy !== '') {
                let data = {
                    filters: this.crops.filters.filter(filter => filter.value.trim() !== ''),
                    ...crops
                };
                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(cropListUrl(), data).then(response => {
                    if (response.data.code === 'OK') {
                        this.crops.totalRows = response.data.totalRows;
                        return response.data.crops;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    if (error.response) {
                        this.$bvToast.toast(error.response.data.message, {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else if (error.request) {
                        this.$bvToast.toast('An error occurred while executing the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else {
                        this.$bvToast.toast('An error occurred while configuring the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        saveCrops() {
            this.isLoading = true;

            this.axios.post(cropSaveUrl(this.cropEntity.id), this.cropEntity).then(response => {
                if (response.data.code === 'OK') {
                    this.$bvModal.hide('crops-modal');
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'success'
                    });
                    this.$refs.cropsTable.refresh();
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        deleteCrop(cropId) {
            this.isLoading = true;

            this.$swal({
                title: 'Are you sure to delete Crop?',
                icon: 'warning',
                iconColor: '#bf0d0d',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#bf0d0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.axios.post(cropDeleteUrl(), {
                        id: cropId
                    }).then(response => {
                        if (response.data.code === 'OK') {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });
                            this.$refs.cropsTable.refresh();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        showModalCrop(row) {
            if (row.id > 0) {
                this.cropEntity = JSON.parse(JSON.stringify(row));
                this.getCropFamily();
            } else {
                this.cropEntity = CropsEntity.create();
            }

            this.$bvModal.show('crops-modal');
        },
        getCropFamily() {
            this.axios.get(cropFamilySelectUrl()).then(response => {
                this.cropFamily = response.data.families;
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            });
        },
        tableContextChanged(context) {
            this.crops.sortBy = context.sortBy;
            this.crops.sortDesc = context.sortDesc;
            this.saveTableState(this.$route.name, this.crops);
        }
    },
    watch: {
        'crops.filters': {
            handler() {
                this.$refs.cropsTable.refresh();
                this.saveTableState(this.$route.name, this.crops);
            },
            deep: true
        }
    },
    computed: {
        start() {
            return this.crops.totalRows === 0 ? 0 : (this.crops.currentPage - 1) * this.crops.itemsPerPage + 1;
        },
        end() {
            let offset = (this.crops.currentPage - 1) * this.crops.itemsPerPage;

            return (this.crops.totalRows - offset) < this.crops.itemsPerPage ? this.crops.totalRows : offset + this.crops.itemsPerPage;
        },
        filteredFields() {
            if (this.hasPermission) {
                return this.crops.fields;
            } else {
                return this.crops.fieldsList;
            }
        }
    }
};
</script>